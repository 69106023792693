import React, { useState } from 'react';
import './Contactus.css';;

const ContactUs = () => {
        const [formData, setFormData] = useState({
            name: '',
            company: '',
            email: '',
            phone: '',
            comment: '',
        });

        const [responseMessage, setResponseMessage] = useState('');

        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        };

        const handleSubmit = async(e) => {
            e.preventDefault();
            try {
                const response = await fetch('http://localhost:5000/contact/submit-form', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    const data = await response.json();
                    setResponseMessage(data.message || 'Form submitted successfully!');
                    setFormData({ name: '', company: '', email: '', phone: '', comment: '' });
                } else {
                    setResponseMessage('Failed to submit form. Please try again.');
                }
            } catch (error) {
                setResponseMessage('An error occurred. Please try again.');
                console.error('Error submitting form:', error);
            }
        };

        return ( <
            div className = "contact-container" >
            <
            div className = "container" > { /* Header Section */ } <
            div className = "contact-header" >
            <
            h2 className = "contact-title" > Contact Us < /h2> <
            nav className = "breadcrumb" >
            <
            a href = "/"
            className = "breadcrumb-link" > Home < /a> &gt; <span>Contact Us</span >
            <
            /nav> < /
            div >

            { /* Contact Content */ } <
            div className = "contact-content" > { /* Contact Information */ } <
            div className = "contact-info" >
            <
            h3 > BluPeaks < /h3> <
            p > We 'd love to hear from you!</p> <
            h4 > Call Us < /h4> <
            p > Phone: 1 - 732 - 723 - 7462 < /p> <
            h4 > Email Us < /h4> <
            p > Email: info @blupeaks.com < /p> < /
            div >

            { /* Contact Form */ } <
            div className = "contact-form" >
            <
            p > Please fill out the form below, and one of our client service leaders will reach out to you shortly. < /p> <
            form onSubmit = { handleSubmit } >
            <
            label >
            Name *
            <
            input type = "text"
            name = "name"
            value = { formData.name }
            onChange = { handleChange }
            required /
            >
            <
            /label> <
            label >
            Company *
            <
            input type = "text"
            name = "company"
            value = { formData.company }
            onChange = { handleChange }
            required /
            >
            <
            /label> <
            label >
            Email *
            <
            input type = "email"
            name = "email"
            value = { formData.email }
            onChange = { handleChange }
            required /
            >
            <
            /label> <
            label >
            Phone *
            <
            input type = "tel"
            name = "phone"
            value = { formData.phone }
            onChange = { handleChange }
            required /
            >
            <
            /label> <
            label >
            Comment *
            <
            textarea name = "comment"
            value = { formData.comment }
            onChange = { handleChange }
            required /
            >
            <
            /label> <
            button type = "submit"
            className = "submit-button" > Send < /button> < /
            form > {
                responseMessage && < p className = "response-message" > { responseMessage } < /p>} < /
                div > <
                /div> < /
                div > <
                /div>
            );
        };

        export default ContactUs;