import React from 'react';
import Slider from 'react-slick';
import "./Testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

const testimonials = [{
        text: "Considering my experience with BluPeaks, I would happily recommend them to anyone in need of IT staffing services. They enable me to focus on critical project delivery and team management, rather than worrying about staffing needs. Their team has consistently provided top talent that aligns perfectly with our technical requirements and company culture. IT Director, Technology Solutions Firm in Irving, TX."
    },
    {
        text: "Based on my years of experience with BluPeaks, I confidently recommend them to any organization in need of skilled IT staffing solutions. Their expertise allows me to concentrate on driving technical strategy and project execution, without the distractions of recruitment and resourcing. IT Director, Technology Firm in Irving, TX."
    },
    {
        text: "We were referred to BluPeaks by one of their clients, and from the beginning, I was impressed by the team’s professionalism and depth of experience in IT staffing. They understood our specific needs and consistently provided high-quality candidates until we shortlisted five for placement, all exceeding our expectations. – Mr. Morgan, IT Administrator at a Major Corporation"
    }
];

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return ( <
        div className = "testimonials-section" >
        <
        p className = "testimonials-title" > See what people are saying about < /p> <
        Slider {...settings } > {
            testimonials.map((testimonial, index) => ( <
                div key = { index }
                className = "testimonial" >
                <
                p className = "testimonial-text" > { testimonial.text } < /p> <
                /div>
            ))
        } <
        /Slider> <
        /div>
    );
};

export default Testimonials;