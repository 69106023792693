import React from 'react';
import './Ahome.css';

const HomePage = () => {
    return ( <
        div className = "home-container" > { /* Hero Section */ } <
        section className = "hero-section" >
        <
        div className = "hero-content" >
        <
        h1 className = "hero-title" > Welcome to BluPeaks < /h1> <
        p className = "hero-subtitle" >
        Your Trusted Partner in IT Solutions, Recruitment, and Cybersecurity <
        /p> <
        /div> <
        /section>

        { /* Main Content Section */ } <
        main className = "main-content" >
        <
        div className = "content-container" >
        <
        h2 className = "section-title" > Empowering Your Business with Innovation < /h2> <
        p className = "section-description" >
        BluPeaks is a Business Enterprise with over 10 years of in -depth experience in end - to - end recruitment, software development, web development, cybersecurity, and cloud solutions.Our staffing expertise connects the right technical talent to critical roles, whether
        for permanent positions or temporary assignments. <
        /p> <
        p className = "section-description" >
        We are committed to delivering customized, effective solutions that meet your IT, network infrastructure, cloud, and cybersecurity needs.With strategies that integrate seamlessly into your business model, we provide the technical and operational support required to drive productivity. <
        /p> <
        p className = "section-highlight" >
        Why wait ? Let’ s elevate your digital and cloud capabilities today. <
        /p> <
        button className = "btn-read-more" > Read More < /button> <
        /div> <
        /main> <
        /div>
    );
};

export default HomePage;