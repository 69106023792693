import React from 'react';
import './Services.css';

const AboutUs = () => {
    return ( <
        section className = "services-container" >
        <
        div className = "container" > { /* Header Section */ } <
        header className = "services-header" >
        <
        h2 className = "services-title" > Our Services < /h2> <
        nav className = "breadcrumb" >
        <
        a href = "/"
        className = "breadcrumb-link" > Home < /a> &gt; <span>Services</span >
        <
        /nav> <
        /header>

        { /* Description Section */ } <
        article className = "services-description" >
        <
        p >
        Leader in Contract, Contract - to - Hire, Locum, Travel, Per Diem, and Direct Hire Staffing. <
        /p> <
        p >
        With a team of dedicated staffing professionals supported by in -depth industry experience and a robust internal database of screened candidates, we are able to work on a wide range of staffing needs and provide options within a short span.Contact us
        for specific turnaround times on various jobs. <
        /p> <
        p >
        BluPeaks is your one - stop shop
        for all your staffing needs.Our solutions team goes beyond the services typically provided by a technical staffing agency.We assist our clients in managing their entire staffing requirements.BluPeaks coordinates with multiple vendors and streamlines your entire contingent workforce needs. <
        /p> <
        /article>

        { /* Areas of Focus */ } <
        section className = "focus-section" >
        <
        h3 className = "focus-title" > BluPeaks Areas of Focus < /h3> <
        ul className = "focus-list" >
        <
        li > Technical Staffing < /li> <
        li > Cloud Infrastructure < /li> <
        li > IT, Administrative, and Operations < /li> <
        /ul> <
        p className = "services-description" >
        Below is a representative list of technical jobs filled
        for our clients across the US. <
        /p> <
        /section>

        { /* Current Priorities */ } <
        section className = "priority-section" >
        <
        h3 className = "service-priority" > What is your current priority ? < /h3> <
        ul className = "priority-list" >
        <
        li > Network Operations, Network Security < /li> <
        li > Data Center, Migration < /li> <
        li > Remote Jobs in the Technical Domain < /li> <
        li > Software Professionals < /li> <
        li > Cloud & IT Domain < /li> <
        li > Computer System Validation(CSV) < /li> <
        li > Data Science < /li> <
        li > Data Analyst < /li> <
        li > SEO < /li> <
        li > Web Creation < /li> <
        li > Linux & System Administration < /li> <
        li > Quality Assurance Analyst < /li> <
        li > Cyber Security < /li> <
        li > JAVA, Python, DevOps < /li> <
        li > Programming Languages < /li> <
        li > Scientists– Various < /li> <
        li > IT, Accounting, and Administrative Roles < /li> <
        /ul> <
        p className = "services-conclusion" >
        Whatever your needs are, we have talented candidates
        for it!
        <
        /p> <
        /section> <
        /div> <
        /section>
    );
};

export default AboutUs;