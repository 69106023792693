import React from 'react';
import './Footer.css'; // Make sure to style it properly

const Footer = () => {
    return ( <
        footer className = "footer" >
        <
        div className = "footer-container" >
        <
        div className = "footer-row" > { /* Contact Us Section */ } <
        div className = "footer-col" >
        <
        h2 className = "footer-title" > Contact Us < /h2> <
        ul className = "contact-details" >
        <
        li >
        <
        i className = "fas fa-phone contact-icon" > < /i> <
        span > Phone: < /span> +1 732 723 7462 < /
        li > <
        li >
        <
        i className = "fas fa-envelope contact-icon" > < /i> <
        span > Email: < /span> info@blupeaks.com < /
        li > <
        li >
        <
        i className = "fas fa-map-marker-alt contact-icon" > < /i> <
        span > Address: < /span> ORLANDO, FL 32837 < /
        li > <
        /ul> < /
        div >

        { /* Quick Links Section */ } <
        div className = "footer-col" >
        <
        h2 className = "footer-title" > Quick Links < /h2> <
        ul className = "footer-links" >
        <
        li > < a href = "#" > Home < /a></li >
        <
        li > < a href = "#" > About Us < /a></li >
        <
        li > < a href = "#" > Services < /a></li >
        <
        li > < a href = "#" > Careers < /a></li >
        <
        li > < a href = "#" > Contact < /a></li >
        <
        /ul> < /
        div >

        { /* About Us Section */ } <
        div className = "footer-col" >
        <
        h2 className = "footer-title" > About Us < /h2> <
        p >
        BluPeaks is a trusted partner with over 10 years of expertise in recruitment, software design, and development.Our mission is to drive innovation and success
        for our clients. <
        /p> <
        a className = "read-more-button"
        href = "aboutus" > Learn More < /a> < /
        div > <
        /div> < /
        div >

        { /* Footer Bottom */ } <
        div className = "footer-bottom" >
        <
        p >
        &
        copy; { new Date().getFullYear() }
        BluPeaks.All rights reserved. |
        <
        a href = "privacy-policy" > Privacy Policy < /a> | <a href="terms-of-service">Terms of Service</a >
        <
        /p> < /
        div > <
        /footer>
    );
};

export default Footer;