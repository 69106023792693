import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return ( <
        div className = "about-us-container" >
        <
        div className = "container" > { /* Header Section */ } <
        div className = "about-us-header" >
        <
        h1 className = "about-us-title" > ABOUT US < /h1> <
        div className = "breadcrumb" >
        <
        a href = "/"
        className = "breadcrumb-link" > Home < /a> &gt; <span>About Us</span >
        <
        /div> <
        /div>

        { /* Description Section */ } <
        div className = "about-us-content" >
        <
        p className = "about-us-description" >
        BluPeaks is a Business Enterprise with over 10 years of in -depth experience in end - to - end recruitment, software development, web development, cybersecurity, and cloud solutions within the network domain.Our staffing expertise connects the right technical talent to critical roles, whether
        for permanent positions or temporary assignments. <
        /p> <
        p className = "about-us-description" >
        We are committed to delivering customized, effective solutions that meet your IT, network infrastructure, cloud, and cybersecurity needs.With strategies that integrate seamlessly into your business model, we provide the technical and operational support required to drive productivity across software, web, and cloud services with secure network management. <
        /p> <
        p className = "about-us-description highlight" >
        Why wait ? Let’ s elevate your digital and cloud capabilities today. <
        /p> <
        /div>

        { /* Strengths Section */ } <
        div className = "our-strengths-section" >
        <
        h2 className = "our-strengths-title" > Our Strengths < /h2> <
        ul className = "strengths-list" >
        <
        li > Extensive internal database of IT professionals across a wide range of specialties < /li> <
        li > Dedicated team of skilled IT recruiters < /li> <
        li > Coverage across Eastern to Pacific time zones
        for seamless support < /li> <
        li > Proven staffing experience across multiple industries < /li> <
        li > Client portfolio ranging from small tech startups to large corporations and enterprises < /li> <
        li > 19 + years of success in IT staffing, backed by solid financials and stable management < /li> <
        /ul> <
        /div> <
        /div> <
        /div>
    );
};

export default AboutUs;