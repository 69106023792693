import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

const ImageSlider = () => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        nextArrow: < CustomNextArrow / > ,
        prevArrow: < CustomPrevArrow / > ,
    };

    return ( <
        div className = "slider-container" >
        <
        Slider ref = { sliderRef } {...settings } >
        <
        div className = "slider-item" >
        <
        img src = "/banner1.jpg"
        alt = "Slide 1"
        className = "slider-image" / >
        <
        div className = "slider-caption" > Welcome to Our Services < /div> <
        /div> <
        div className = "slider-item" >
        <
        img src = "/banner2.jpg"
        alt = "Slide 2"
        className = "slider-image" / >
        <
        div className = "slider-caption" > Your Success is Our Goal < /div> <
        /div> <
        div className = "slider-item" >
        <
        img src = "/banner3.jpg"
        alt = "Slide 3"
        className = "slider-image" / >
        <
        div className = "slider-caption" > Innovative Solutions
        for You < /div> <
        /div> <
        /Slider> <
        /div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return ( <
        div className = { `${className} custom-arrow custom-next` }
        style = {
            {...style } }
        onClick = { onClick }
        />
    );
};

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return ( <
        div className = { `${className} custom-arrow custom-prev` }
        style = {
            {...style } }
        onClick = { onClick }
        />
    );
};

export default ImageSlider;