import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, description, buttonText, buttonStyle, buttonLink }) => {
    return ( <
        div className = "service-card" >
        <
        div className = "service-card-icon" > { icon } < /div> <
        h3 className = "service-card-title" > { title } < /h3> <
        p className = "service-card-description" > { description } < /p> <
        Link to = { buttonLink }
        className = "service-card-button"
        style = { buttonStyle } > { buttonText } <
        /Link> <
        /div>
    );
};

const Services = () => {
    const servicesData = [{
            icon: < img src = "/experience.png"
            alt = "RPO Services" / > ,
            title: "RPO SERVICES",
            description: "Our strategy is focused upon results-driven RPO services.",
            buttonText: "Read More",
            buttonLink: "/mainservices",
        },
        {
            icon: < img src = "/marketing.png"
            alt = "US Staffing" / > ,
            title: "US Staffing",
            description: "We have many open positions for staffing professionals.",
            buttonText: "Read More",
            buttonLink: "/mainservices",
        },
        {
            icon: < img src = "/search.png"
            alt = "Training" / > ,
            title: "Training",
            description: "Take charge of your career. Let us help you find a path.",
            buttonText: "Read More",
            buttonLink: "/mainservices",
        },
        {
            icon: < img src = "/target.png"
            alt = "Placement" / > ,
            title: "Placement",
            description: "A focused training and placement solutions provider.",
            buttonText: "Read More",
            buttonLink: "/mainservices",
        },
    ];

    return ( <
        section className = "our-services" >
        <
        div className = "our-services-container" >
        <
        h2 className = "our-services-title" > Our Services < /h2> <
        p className = "our-services-description" >
        We are here to provide you with exceptional services always. <
        /p> <
        div className = "service-cards" > {
            servicesData.map((service, index) => ( <
                ServiceCard key = { index } {...service }
                />
            ))
        } <
        /div> <
        /div> <
        /section>
    );
};

export default Services;